/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import { RouteObject } from 'react-router-dom';

import app from './app';
import resto from './resto';

export const routes: {
  app: RouteObject[];
  resto: RouteObject[];
} = {
  app: [app],
  resto: [resto],
};
