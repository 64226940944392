import React from 'react';
import { IndexRouteObject, NonIndexRouteObject } from 'react-router-dom';

import { RouteProtected } from 'components/@common';

export type Route = Omit<NonIndexRouteObject, 'element'> & {
  authenticated?: boolean;
};

export type IndexRoute = Omit<IndexRouteObject, 'element'> & {
  authenticated?: boolean;
};

export const createRoute = (
  Component: React.LazyExoticComponent<React.FC>,
  config?: Route,
) => {
  const routeObject = {
    ...config,
    element: <Component />,
  };

  if (config?.authenticated) {
    routeObject.element = (
      <RouteProtected>
        <Component />
      </RouteProtected>
    );
  }

  return routeObject;
};

export const createIndexRoute = (
  Component: React.LazyExoticComponent<React.FC>,
  config?: IndexRoute,
) => {
  const routeObject: IndexRouteObject = {
    ...config,
    index: true,
    element: <Component />,
  };

  if (config?.authenticated) {
    routeObject.element = (
      <RouteProtected>
        <Component />
      </RouteProtected>
    );
  }

  return routeObject;
};
