import { memo, SVGProps } from 'react';

const SvgIcoFlashlight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_1122_8841)">
      <path
        d="M6 3L8.472 7.944C8.819 8.639 9 9.404 9 10.18L9 22.193C9.221 23.151 10.558 24 12.085 24C13.557 24 14.771 23.188 15 22.193L15 10.18C15 9.405 15.149 8.639 15.497 7.945C17.249 4.496 18 3 18 3L6 3ZM13 14C13 14.552 12.552 15 12 15C11.448 15 11 14.552 11 14C11 13.448 11.448 13 12 13C12.552 13 13 13.448 13 14ZM6 0L18 -5.24537e-07L18 2L6 2L6 0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1122_8841">
        <rect
          width={24}
          height={24}
          fill="currentColor"
          transform="translate(0 24) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoFlashlight);
export default Memo;
