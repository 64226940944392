import { Auth } from '@aws-amplify/auth';
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types';

import config from 'config';

import { MobileDetect } from 'utils/browser.utils';
import { isTest } from 'utils/env.utils';

const { AMPLIFY, COOKIE_DOMAIN } = config;

const cookieStorage: AuthOptions['cookieStorage'] = {
  domain: COOKIE_DOMAIN,
  expires: 365,
};

const OAUTH_STATE_KEY = 'oauth_state';
const OAUTH_PKCE_KEY = 'ouath_pkce_key';

const updateLocalStorage = () => {
  const oauthSate = sessionStorage.getItem(OAUTH_STATE_KEY);
  if (oauthSate) {
    localStorage.setItem(OAUTH_STATE_KEY, oauthSate);
  }
  const oauthPkce = sessionStorage.getItem(OAUTH_PKCE_KEY);
  if (oauthPkce) {
    localStorage.setItem(OAUTH_PKCE_KEY, oauthPkce);
  }
};

const updateSessionStorage = () => {
  const oauthSate = localStorage.getItem(OAUTH_STATE_KEY);
  if (oauthSate) {
    localStorage.removeItem(OAUTH_STATE_KEY);
    sessionStorage.setItem(OAUTH_STATE_KEY, oauthSate);
  }
  const oauthPkce = localStorage.getItem(OAUTH_PKCE_KEY);
  if (oauthPkce) {
    localStorage.removeItem(OAUTH_PKCE_KEY);
    sessionStorage.setItem(OAUTH_PKCE_KEY, oauthPkce);
  }
};

export const initAmplify = (platform: 'app' | 'resto') => {
  const config = platform === 'app' ? AMPLIFY.CONSUMER : AMPLIFY.RESTO;

  if (MobileDetect.Android()) {
    updateSessionStorage();
  }

  const authConfig: AuthOptions = {
    region: AMPLIFY.REGION,
    userPoolId: AMPLIFY.USER_POOL_ID,
    userPoolWebClientId: config.CLIENT_ID,
    cookieStorage: isTest() ? undefined : cookieStorage,
    oauth: {
      domain: AMPLIFY.DOMAIN,
      responseType: 'code',
      scope: ['aws.cognito.signin.user.admin', 'profile', 'email', 'openid'],
      redirectSignIn: config.REDIRECT_URL,
      redirectSignOut: config.LOGOUT_REDIRECT_URL,
      urlOpener: async (url: string, _redirectUrl: string) => {
        // We move the states from session storage to local storage because the sessions storage is not shared with the pwa.
        // This makes it possible to login from the broser and end up in the pwa.
        if (MobileDetect.Android()) {
          updateLocalStorage();
        }
        window.open(url, '_self');
        return;
      },
    },
  };

  Auth.configure({ Auth: authConfig });
};
