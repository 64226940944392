import { Components, Theme } from '@mui/material';

export const MuiListItemText: Components<Theme>['MuiListItemText'] = {
  styleOverrides: {
    primary: ({ theme }) => ({
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.18rem',
    }),
    secondary: ({ theme }) => ({
      color: theme.palette.text.primary,
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '0.87rem',
    }),
  },
};
