import { Auth, CognitoUser } from '@aws-amplify/auth';
import {
  QueryClient,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query';
import posthog from 'posthog-js';

import { QueryKeys, StaleTime } from 'queries';

const getUser = async () => {
  const data: CognitoUser = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });

  return data;
};

const userQuery: WithRequired<
  UseQueryOptions<CognitoUser, string>,
  'queryKey'
> = {
  queryKey: QueryKeys.user.me(),
  queryFn: () => getUser(),
  retry: (_, err) => err !== 'The user is not authenticated', // This value comes from cognito itself, but doens't throw a 401
  staleTime: StaleTime.FOREVER,
};

export const userLoader = async (queryClient: QueryClient) => {
  try {
    const user = await queryClient.fetchQuery(userQuery);
    return user;
  } catch (err) {
    return null;
  }
};

export const useUser = (config: UseQueryOptions<CognitoUser, string> = {}) => {
  const { data, isLoading } = useQuery({
    ...userQuery,
    ...config,
    onSuccess: (user) => {
      config.onSuccess?.(user);
      posthog.identify(user.getUsername());
    },
  });

  return {
    data,
    isLoggedIn: !!data,
    isLoading,
  };
};
