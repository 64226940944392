import { AppTypeContext } from 'App';
import { useContext } from 'react';

export const useIsResto = () => {
  const appType = useContext(AppTypeContext);

  if (appType === 'resto') {
    return true;
  }
  return false;
};
