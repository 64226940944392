import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { PersistQueryClientOptions } from '@tanstack/react-query-persist-client';
import { AxiosError } from 'axios';

import { QueryKeys } from 'queries';

const queryCache = new QueryCache();

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: unknown) => {
        const status = (error as AxiosError)?.response?.status;
        const code = (error as AxiosError)?.code;
        if (status === 401 || status === 404 || code === 'ECONNABORTED') {
          return false;
        }
        return failureCount < 2;
      },
    },
  },
});

const persistQueries: string[] = [
  QueryKeys.user.details(),
  QueryKeys.user.transactions(),
].map((q) => q.join('-'));

export const persisOptions: Omit<PersistQueryClientOptions, 'queryClient'> = {
  persister: localStoragePersister,
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) => {
      const finalKey = queryKey.join('-');
      return !!persistQueries.find((q) => finalKey.includes(q));
    },
  },
};
