import { Components } from '@mui/material';

import AgneRegularWoff2 from 'assets/fonts/Agne-Regular.woff2';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: `
    :root {
      --sat: env(safe-area-inset-top);
      --sar: env(safe-area-inset-right);
      --sab: env(safe-area-inset-bottom);
      --sal: env(safe-area-inset-left);
    }

    body {
      min-height: fill-available;
      background-color: #EDE7D6;
      -webkit-tap-highlight-color: transparent;
    }

    #root {
      height: 100vh;
      height: 100dvh;
    }

    @font-face {
      font-family: 'Agne';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: local('Agne'), url(${AgneRegularWoff2}) format('woff2');
    }
  `,
};
