import { Components, Theme } from '@mui/material';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral07.main,
      marginLeft: 0,
    }),
    error: ({ theme }) => ({
      color: theme.palette.error.main,
    }),
  },
};
