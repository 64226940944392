export const isSafariBrowser = () => {
  return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
};

export const MobileDetect = {
  Android: function () {
    return !!navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return !!navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return !!navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      !!navigator.userAgent.match(/IEMobile/i) ||
      !!navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      MobileDetect.Android() ||
      MobileDetect.BlackBerry() ||
      MobileDetect.iOS() ||
      MobileDetect.Opera() ||
      MobileDetect.Windows()
    );
  },
};
