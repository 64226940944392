import { SVGProps, memo } from 'react';
const SvgIcoHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M6.20645 6.12195C6.43205 5.48064 6.87735 4.93986 7.46346 4.5954C8.04958 4.25094 8.73869 4.12503 9.40875 4.23996C10.0788 4.35489 10.6866 4.70325 11.1244 5.22334C11.5622 5.74343 11.8018 6.40169 11.8008 7.08152C11.8008 9.00066 8.92207 9.96023 8.92207 9.96023M8.99884 13.7985H9.00843"
      stroke="white"
      strokeWidth={1.91915}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Memo = memo(SvgIcoHelp);
export default Memo;
