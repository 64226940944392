import {
  ConfirmParams,
  ConfirmResponse,
  Tableware,
} from 'types/tableware.types';

import RestApiService from './';

export default {
  getTablewareDetails(
    this: RestApiService,
    isResto: boolean,
    id: Tableware['id'],
  ) {
    return this.api.get<Tableware>(
      `/${isResto ? 'restaurants' : 'consumers'}/tableware/${id}`,
      { authEnabled: true },
    );
  },
  getTablewareDropoffPoints(this: RestApiService, id: Tableware['id']) {
    return this.api.get<{ dropOffLocations: string }>(
      `/consumers/tableware/${id}/locations`,
      {
        authEnabled: true,
      },
    );
  },
  confirmScans(this: RestApiService, isResto: boolean, params: ConfirmParams) {
    return this.api.post<ConfirmResponse>(
      `/${isResto ? 'restaurants' : 'consumers'}/confirm-scans`,
      params,
      {
        authEnabled: true,
      },
    );
  },
};
