import { SCANNER_ITEM_ADDED } from 'constants/events.constants';
import { startAppListening } from 'services/redux/listenerMiddleware';

import { scannerActions } from './scanner.slice';

export default function startNavigationListeners() {
  startAppListening({
    predicate: (action) => action.type === scannerActions.ADD_ITEM.type,
    effect: (action, listenerApi) => {
      listenerApi.cancelActiveListeners();
      const item = action.payload;
      if (item) {
        const event = new CustomEvent(SCANNER_ITEM_ADDED, {
          detail: {
            item,
          },
        });
        dispatchEvent(event);
      }
    },
  });
}
