import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Tableware } from 'types/tableware.types';

interface ScannerState {
  items: Tableware[];
  brokenIds: Tableware['id'][];
}

const initialState: ScannerState = {
  items: [],
  brokenIds: [],
};

const scannerSlice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {
    ADD_ITEM: (state, { payload }: PayloadAction<ScannerState['items'][0]>) => {
      state.items = [payload, ...state.items];
    },
    UPDATE_BROKEN: (
      state,
      { payload }: PayloadAction<{ id: Tableware['id']; broken: boolean }>,
    ) => {
      if (payload.broken) {
        state.brokenIds = [payload.id, ...state.brokenIds];
      } else {
        state.brokenIds = state.brokenIds.filter((i) => i !== payload.id);
      }
    },
    REMOVE_ITEM: (
      state,
      { payload }: PayloadAction<ScannerState['items'][0]['id']>,
    ) => {
      state.items = state.items.filter((i) => i.id !== payload);
      state.brokenIds = state.brokenIds.filter((i) => i !== payload);
    },
    CLEAR_ITEMS: (state) => {
      state.items = [];
      state.brokenIds = [];
    },
  },
});

export const { actions: scannerActions } = scannerSlice;

export default scannerSlice.reducer;
