import { Components, outlinedInputClasses, Theme } from '@mui/material';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette.neutral05.main,
      borderRadius: 8,
      borderWidth: 1,
      padding: theme.spacing(1.25, 1.75),
      [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
        {
          borderWidth: 1,
          borderColor: theme.palette.neutral07.main,
        },
      [`&:hover .${outlinedInputClasses.notchedOutline}`]: {},
      [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]:
        {
          backgroundColor: theme.palette.neutral03.main,
          borderWidth: 1,
        },
    }),
    notchedOutline: {
      top: 0,
      [`& legend`]: {
        display: 'none',
      },
    },
    input: ({ theme }) => ({
      padding: 0,
      boxSizing: 'border-box',
      '&::placeholder': {
        color: theme.palette.neutral07.main,
        fontWeight: 400,
      },

      [`&:disabled`]: {
        backgroundColor: '#E6E6E6',
      },
    }),
  },
};
