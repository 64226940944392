import React from 'react';

import { createRoute } from 'utils/router.utils';

import { Routes } from 'pages/routes.constants';
import completePassword from 'pages/shared/auth/complete-password';
import forgotPassword from 'pages/shared/auth/forgot-password';
import login from 'pages/shared/auth/login';
import resetPassword from 'pages/shared/auth/reset-password';

import callback from './callback';
import register from './register';

/**
 * use lazy imports to get code splitting out-of-the-box
 * you can use "webpackChunkName" to name this chunk
 */
// tslint:disable:space-in-parens
const componentPromise = import(
  './Auth' /* webpackChunkName: "auth", webpackPreload: true */
);
const Component = React.lazy(() => componentPromise);
// tslint:enable

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default createRoute(Component, {
  path: Routes.Auth,
  children: [
    register,
    login,
    forgotPassword,
    resetPassword,
    completePassword,
    callback,
  ],
});
