import { memo, SVGProps } from 'react';

const SvgIcoTablewareBowl = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" {...props}>
    <path
      d="M8.66667 19L6 9H22L19.3333 19H8.66667Z"
      fill="currentFill"
      stroke="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoTablewareBowl);
export default Memo;
