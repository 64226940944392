import { memo, SVGProps } from 'react';

const SvgIcoTablewareUnknown = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" {...props}>
    <path d="M10.6667 20L9 8H19L17.3333 20H10.6667Z" fill="white" />
    <path
      d="M13.3356 15.2898V15.2159C13.3374 14.8731 13.3696 14.6004 13.4321 14.3977C13.4965 14.1951 13.5874 14.0322 13.7049 13.9091C13.8223 13.7841 13.9653 13.6695 14.1339 13.5653C14.2494 13.4934 14.3526 13.4148 14.4435 13.3295C14.5363 13.2424 14.6092 13.1458 14.6623 13.0398C14.7153 12.9318 14.7418 12.8115 14.7418 12.679C14.7418 12.5293 14.7068 12.3996 14.6367 12.2898C14.5666 12.1799 14.4719 12.0947 14.3526 12.0341C14.2352 11.9735 14.1035 11.9432 13.9577 11.9432C13.8232 11.9432 13.6954 11.9725 13.5742 12.0312C13.4549 12.0881 13.3554 12.1752 13.2759 12.2926C13.1982 12.4081 13.1547 12.5549 13.1452 12.7329H12.1339C12.1433 12.3731 12.2304 12.072 12.3952 11.8295C12.5619 11.5871 12.7816 11.4053 13.0543 11.2841C13.3289 11.1629 13.632 11.1023 13.9634 11.1023C14.3251 11.1023 14.6433 11.1657 14.9179 11.2926C15.1945 11.4195 15.4094 11.6004 15.5628 11.8352C15.7181 12.0682 15.7958 12.3447 15.7958 12.6648C15.7958 12.8807 15.7607 13.0739 15.6907 13.2443C15.6225 13.4148 15.5249 13.5663 15.3981 13.6989C15.2712 13.8314 15.1206 13.9498 14.9464 14.054C14.7929 14.1487 14.667 14.2472 14.5685 14.3494C14.4719 14.4517 14.3999 14.572 14.3526 14.7102C14.3071 14.8466 14.2835 15.0151 14.2816 15.2159V15.2898H13.3356ZM13.8299 17.0625C13.6594 17.0625 13.5126 17.0019 13.3895 16.8807C13.2664 16.7595 13.2049 16.6117 13.2049 16.4375C13.2049 16.267 13.2664 16.1212 13.3895 16C13.5126 15.8788 13.6594 15.8182 13.8299 15.8182C13.9984 15.8182 14.1443 15.8788 14.2674 16C14.3924 16.1212 14.4549 16.267 14.4549 16.4375C14.4549 16.553 14.4255 16.6581 14.3668 16.7528C14.31 16.8475 14.2342 16.9233 14.1395 16.9801C14.0467 17.035 13.9435 17.0625 13.8299 17.0625Z"
      fill="#6F6D68"
    />
  </svg>
);
const Memo = memo(SvgIcoTablewareUnknown);
export default Memo;
