import { AxiosInstance } from 'axios';

import { getAccessToken } from 'services/amplify/tokens';
import store from 'store';
import { getLocale } from 'store/intl/intl.selectors';

export const createHeaderRequestInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(async (request) => {
    request.headers = request.headers ?? {};

    if (request.authEnabled) {
      const accessToken = await getAccessToken();

      if (accessToken) {
        request.headers.Authorization = accessToken;
      }
    }

    const state = store.store.getState();

    const locale = getLocale(state);

    request.headers['Accept-Language'] = locale.toUpperCase();

    request.headers['Accept'] = 'application/json';

    return request;
  });
};
