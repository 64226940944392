import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useMutation } from '@tanstack/react-query';

import { SocialProviders } from 'types/amplify.types';

interface Variables {
  provider: SocialProviders;
}

const ProviderLookup = {
  [SocialProviders.Apple]: CognitoHostedUIIdentityProvider.Apple,
  [SocialProviders.Google]: CognitoHostedUIIdentityProvider.Google,
};

const loginSocial = async ({ provider }: Variables) =>
  Auth.federatedSignIn({
    provider: ProviderLookup[provider],
  });

export const useSocialLogin = () => {
  const { mutate, isLoading, error, isSuccess } = useMutation(loginSocial);

  return { loginSocial: mutate, isLoading, error, isSuccess };
};
