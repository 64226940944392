import React from 'react';

import { createRoute } from 'utils/router.utils';

import notFound from 'pages/not-found';
import { Routes } from 'pages/routes.constants';
import onboarding from 'pages/shared/onboarding';

import auth from './auth';
import home from './home';
import refund from './refund';
import scan from './scan';

/**
 * use lazy imports to get code splitting out-of-the-box
 * you can use "webpackChunkName" to name this chunk
 */
// tslint:disable:space-in-parens
const componentPromise = import(
  './App' /* webpackChunkName: "app", webpackPreload: true */
);
const Component = React.lazy(() => componentPromise);
// tslint:enable

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default createRoute(Component, {
  path: Routes.Root,
  children: [home, auth, onboarding, scan, refund, notFound],
});
