import { Box, Link, Stack, Typography } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

import { BorroLogo } from 'components/@icons';

import { FallbackProps } from './boundary.types';

const Fallback = ({ error }: FallbackProps) => {
  return (
    <Box
      width="100vw"
      height="100vh"
      bgcolor="neutral05.main"
      color="neutral01.main"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Stack gap={2} mb="30px">
        <BorroLogo fontSize={70} />
        <Typography variant="h1">
          <FormattedMessage id="page.404.error" />
        </Typography>
        <Link component={RouterLink} to={Routes.Root}>
          <FormattedMessage id="page.404.link" />
        </Link>
      </Stack>
      {(error as unknown as Error)?.message}
    </Box>
  );
};

interface Props {
  children: ReactNode;
}

const RouteBoundary = ({ children }: Props) => (
  <ErrorBoundary
    beforeCapture={(scope) => scope.setTag('boundary', 'pages')}
    fallback={(props) => <Fallback {...props} />}
  >
    {children}
  </ErrorBoundary>
);

export default RouteBoundary;
