import Cookies from 'js-cookie';
import React from 'react';
import { redirect } from 'react-router-dom';

import { createRoute } from 'utils/router.utils';

import { ONBOARDED } from 'constants/cookies.constants';

import { Routes } from 'pages/routes.constants';

/**
 * use lazy imports to get code splitting out-of-the-box
 * you can use "webpackChunkName" to name this chunk
 */
// tslint:disable:space-in-parens
const componentPromise = import(
  './Onboarding' /* webpackChunkName: "onboarding", webpackPreload: true */
);
const Component = React.lazy(() => componentPromise);
// tslint:enable

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default createRoute(Component, {
  path: Routes.Onboarding,
  loader: async () => {
    const onboardingCookie = Cookies.get(ONBOARDED);

    if (Boolean(onboardingCookie)) {
      return redirect(Routes.Root);
    }

    return null;
  },
});
