import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useUser } from 'queries';

import { Routes } from 'pages/routes.constants';
import { Route as RouteProps } from 'pages/routes.types';

const RouteProtected = ({ children }: PropsWithChildren<RouteProps>) => {
  const { isLoggedIn } = useUser();
  const location = useLocation();

  if (!isLoggedIn) {
    return (
      <Navigate
        to={`${Routes.Auth}/${Routes.Login}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
};

export default RouteProtected;
