import { AxiosInstance } from 'axios';

import scansApi from './scans.api';
import userApi from './user.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // USER
  public getUser = userApi.getUser;
  public updateUser = userApi.updateUser;
  public getUserTransactions = userApi.getUserTransactions;

  // SCANS
  public getTablewareDetails = scansApi.getTablewareDetails;
  public confirmScans = scansApi.confirmScans;
  public getTablewareDropoffPoints = scansApi.getTablewareDropoffPoints;
}

export default RestApiService;
