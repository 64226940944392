import { Components, Theme } from '@mui/material';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 8,
      color: theme.palette.neutral02.main,
      alignItems: 'center',
      fontWeight: 400,
      fontSize: '0.75rem',
    }),
    standardError: ({ theme }) => ({
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
    }),
    standardInfo: ({ theme }) => ({
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.main,
    }),
    message: {
      padding: 0,
    },
  },
};
