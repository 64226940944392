import React from 'react';
import { redirect } from 'react-router-dom';

import { createRoute } from 'utils/router.utils';

import { userLoader } from 'queries';
import { queryClient } from 'services/react-query';

import { Routes } from 'pages/routes.constants';

/**
 * use lazy imports to get code splitting out-of-the-box
 * you can use "webpackChunkName" to name this chunk
 */
// tslint:disable:space-in-parens
const componentPromise = import(
  './Callback' /* webpackChunkName: "callback", webpackPreload: true */
);
const Component = React.lazy(() => componentPromise);
// tslint:enable

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default createRoute(Component, {
  path: Routes.Callback,
  loader: async () => {
    const user = await userLoader(queryClient);

    if (user) {
      return null;
    }

    return redirect(`${Routes.Auth}/${Routes.Login}?error=social_login`);
  },
});
