import { memo, SVGProps } from 'react';

const SvgIcoTablewarePizzabox = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" {...props}>
    <path
      d="M5 15H23V16C23 17.1046 22.1046 18 21 18H7C5.89543 18 5 17.1046 5 16V15Z"
      fill="currentFill"
      stroke="currentColor"
      opacity="0.5"
    />
    <path
      d="M5 13H23V12C23 10.8954 22.1046 10 21 10H7C5.89543 10 5 10.8954 5 12V13Z"
      fill="currentFill"
      stroke="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoTablewarePizzabox);
export default Memo;
