import { QueryKey } from '@tanstack/react-query';

enum Keys {
  User = 'user',
  Tableware = 'tableware',
}

export const QueryKeys = {
  user: {
    me: (): QueryKey => [Keys.User],
    details: (): QueryKey => [Keys.User, 'details'],
    transactions: (): QueryKey => [Keys.User, 'transactions'],
  },
  tableware: {
    dropoff: (id: string): QueryKey => [Keys.Tableware, 'dropoff', id],
  },
};
