export const isDev = () => process.env.REACT_APP_BUILD_ENV === 'development';
export const isProd = () => process.env.REACT_APP_BUILD_ENV === 'production';
export const isTest = () => process.env.REACT_APP_BUILD_ENV === 'test';
export const isStag = () => process.env.REACT_APP_BUILD_ENV === 'staging';
export const runInDev = (func: () => void) => (isDev() ? func() : null);
export const runInStag = (func: () => void) => (isStag() ? func() : null);
export const runInTest = (func: () => void) => (isTest() ? func() : null);
export const runNotInTest = (func: () => void) => (!isTest() ? func() : null);
export const runInProd = (func: () => void) => (isProd() ? func() : null);
export const runNotInDev = (func: () => void) =>
  isProd() || isStag() ? func() : null;
