import { SVGProps, memo } from 'react';
const SvgIcoCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 62 62" fill="none" {...props}>
    <path
      d="M51.4546 15.8182L23.4546 43.8182L10.7273 31.0909"
      stroke="white"
      strokeWidth={4.36364}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Memo = memo(SvgIcoCheck);
export default Memo;
