import posthog from 'posthog-js';

import config from 'config';

export const initPosthog = (platform: 'app' | 'resto') => {
  posthog.init(
    platform === 'resto' ? config.POSTHOG.RESTO_ID : config.POSTHOG.CONSUMER_ID,
    {
      api_host: config.POSTHOG.API_HOST,
    },
  );
};
