import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    fullWidth: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      boxShadow: 'none',
      borderRadius: 48,
      fontWeight: 600,
      padding: theme.spacing(1.75, 1.5),
      fontSize: '1rem',
      lineHeight: '1.1rem',
      textTransform: 'none',
    }),
    containedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.cta01.main,
      '&:hover': {
        backgroundColor: theme.palette.cta01.main,
      },
      '&:disabled': {
        backgroundColor: theme.palette.neutral07.main,
        color: theme.palette.neutral04.main,
      },
    }),
    containedSecondary: ({ theme }) => ({
      color: theme.palette.neutral01.main,
      backgroundColor: theme.palette.neutral02.main,
      '&:hover': {
        backgroundColor: theme.palette.neutral04.main,
      },
      '&:disabled': {
        backgroundColor: theme.palette.neutral02.main,
        opacity: 0.8,
      },
    }),
    outlinedPrimary: ({ theme }) => ({
      border: `2px solid ${theme.palette.cta01.main}`,
      color: theme.palette.cta01.main,
      backgroundColor: 'transparent',
      padding: theme.spacing(1.5, 0),
      '&:hover': {
        backgroundColor: 'transparent',
        border: `2px solid ${theme.palette.cta01.main}`,
        boxShadow:
          '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.04), 0px 1px 10px 0px rgba(0,0,0,0.02)',
      },
    }),
    outlinedSecondary: ({ theme }) => ({
      borderColor: theme.palette.neutral02.main,
      color: theme.palette.neutral02.main,
      '&:hover': {
        border: '1px solid',
        borderColor: theme.palette.neutral02.main,
        opacity: 0.8,
      },
    }),
    outlinedSizeSmall: ({ theme }) => ({
      padding: theme.spacing(0.5, 1.5),
      minWidth: 'unset',
      width: 'fit-content',
      fontSize: 12,
    }),
  },
};
