import { AxiosResponse } from 'axios';

import { TransactionResponse } from 'types/tableware.types';
import type { UpdateUserParams, User } from 'types/user.types';

import RestApiService from './';

export default {
  getUser(this: RestApiService) {
    return this.api.get<User>('/users/me', { authEnabled: true });
  },
  updateUser(this: RestApiService, params: UpdateUserParams) {
    return this.api.patch<UpdateUserParams, AxiosResponse<User>>(
      '/users/me',
      params,
      { authEnabled: true },
    );
  },
  getUserTransactions(this: RestApiService) {
    return this.api.get<TransactionResponse>('/users/me/transactions', {
      authEnabled: true,
    });
  },
};
