import { Components, inputLabelClasses, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      position: 'relative',
      transform: 'none',
      fontSize: 14,
      marginBottom: 4,
      color: theme.palette.neutral08.main,
      fontWeight: 500,
      [`&.${inputLabelClasses.focused}`]: {
        color: theme.palette.neutral08.main,
      },
    }),
    error: ({ theme }) => ({
      color: theme.palette.error.main,
    }),
  },
};
