export enum Routes {
  Root = '/',
  NotFound = '*',

  // Scan flow
  Scan = '/scan',

  // Refund
  Refund = '/refund',

  // onboarding
  Onboarding = 'onboarding',

  // Auth
  Auth = '/auth',
  Callback = 'callback',
  Register = 'register',
  Login = 'login',
  ForgotPassword = 'forgot-password',
  ResetPassword = 'reset-password',
  CompletePassword = 'complete-password',

  // Resto confirm
  Confirm = '/confirm',
}
