import { createTheme } from '@mui/material/styles';

/**
 *   Setup base theme allows to be imported at other places (ex, palette, typography, spacing,...)
 */
export const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 600,
      lg: 900,
      xl: 1200,
    },
  },
  palette: {
    global01: { main: '#E9630B' },
    primary: { main: '#E9630B' },
    neutral01: { main: '#08090B' },
    neutral02: { main: '#FFFFFF' },
    neutral03: { main: '#F2F2F0' },
    neutral04: { main: '#E5E5E0' },
    neutral05: { main: '#EDE7D6' },
    neutral06: { main: '#BCB9B1' },
    neutral07: { main: '#6F6D68' },
    neutral08: { main: '#3D3D3E' },
    neutral09: { main: '#1E1E1E' },
    cta01: { main: '#223A57', light: '#E5ECF5' },
    error: {
      dark: '#771818',
      main: '#B42318',
      light: '#FEF3F2',
    },
    warning: {
      dark: '#AF6409',
      main: '#DE7F0B',
      light: '#F7B05A',
    },
    info: {
      main: '#223A57',
      light: '#E5ECF5',
    },
    progress: {
      main: '#026AA2',
      light: '#F0F9FF',
    },
    success: {
      main: '#276402',
      light: '#E8EFE5',
    },
    text: {
      primary: '#08090B',
      secondary: '#FFFFFF',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: '"Inter", sans-serif',
    fontWeightBold: 600,
    h1: {
      fontFamily: '"Agne", sans-serif',
      fontWeight: 400,
      fontSize: '2.5rem',
      lineHeight: '2.25rem',
    },
    h2: {
      fontFamily: '"Agne", sans-serif',
      fontWeight: 400,
      fontSize: '2rem',
      lineHeight: '2.25rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.56rem',
      lineHeight: '2.25rem',
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.18rem',
    },
    h6: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '0.87rem',
    },
    h7: {
      fontWeight: 600,
      fontSize: '0.62rem',
      lineHeight: '0.75rem',
    },
    caption1: {
      fontWeight: 400,
      fontSize: '0.5rem',
      lineHeight: '0.6rem',
    },
    paragraph1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    paragraph2: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1.12rem',
    },
  },
});
