import { isProd } from 'utils/env.utils';

export interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  COOKIE_DOMAIN: string;
  API_BASE: string;
  RESTO_CLIENT_URL: string;
  CONSUMER_CLIENT_URL: string;
  ANALYTICS_GOOGLE_TRACKING_ID?: string;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  SUPPORT_EMAIL: string;
  AMPLIFY: {
    REGION: string;
    USER_POOL_ID: string;
    DOMAIN: string;
    CONSUMER: {
      CLIENT_ID: string;
      REDIRECT_URL: string;
      LOGOUT_REDIRECT_URL: string;
    };
    RESTO: {
      CLIENT_ID: string;
      REDIRECT_URL: string;
      LOGOUT_REDIRECT_URL: string;
    };
  };
  POSTHOG: {
    RESTO_ID: string;
    CONSUMER_ID: string;
    API_HOST: string;
  };

  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',
  COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN || '',
  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',
  RESTO_CLIENT_URL: process.env.REACT_APP_RESTO_URL || '',
  CONSUMER_CLIENT_URL: process.env.REACT_APP_CONSUMER_URL || '',

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID:
    process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || '',

  // error tracking
  SENTRY: {
    dsn: 'https://80eeb1ed0571429aac867f68dc07f39e@o190235.ingest.sentry.io/4504774972669952',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  SUPPORT_EMAIL: 'hello@borro.one',

  // analytics
  POSTHOG: {
    CONSUMER_ID: process.env.REACT_APP_POSTHOG_CONSUMER_TRACKING_ID || '',
    RESTO_ID: process.env.REACT_APP_POSTHOG_RESTO_TRACKING_ID || '',
    API_HOST: process.env.REACT_APP_POSTHOG_API_HOST || '',
  },

  // amplify
  AMPLIFY: {
    REGION: process.env.REACT_APP_AMPLIFY_REGION || '',
    USER_POOL_ID: process.env.REACT_APP_AMPLIFY_USER_POOL_ID || '',
    DOMAIN: process.env.REACT_APP_AMPLIFY_DOMAIN || '',
    CONSUMER: {
      CLIENT_ID: process.env.REACT_APP_CONSUMER_AMPLIFY_CLIENT_ID || '',
      REDIRECT_URL: `${process.env.REACT_APP_CONSUMER_URL}/auth/callback` || '',
      LOGOUT_REDIRECT_URL: process.env.REACT_APP_CONSUMER_URL || '',
    },
    RESTO: {
      CLIENT_ID: process.env.REACT_APP_RESTO_AMPLIFY_CLIENT_ID || '',
      REDIRECT_URL: `${process.env.REACT_APP_RESTO_URL}/auth/callback` || '',
      LOGOUT_REDIRECT_URL: process.env.REACT_APP_RESTO_URL || '',
    },
  },

  // dev
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'en-GB',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? 'nl',
};

export default config;
