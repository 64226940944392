import { styled } from '@mui/material';
import { motion, Variants } from 'framer-motion';

import { IcoCheck } from 'components/@icons';

const motionCheckVariants: Variants = {
  active: {
    scale: 1,
    opacity: 1,
    transition: { type: 'spring', stiffness: 100, damping: 8 },
  },
  inactive: {
    scale: 0,
    opacity: 0,
  },
};

const MotionCheck = styled(motion.div)(({ theme }) => ({
  width: 120,
  height: 120,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  backgroundColor: theme.palette.success.main,
  fontSize: 60,
}));

interface Props {
  active?: boolean;
}
const BigCheck = ({ active = true }: Props) => {
  return (
    <MotionCheck
      variants={motionCheckVariants}
      initial="inactive"
      animate={active ? 'active' : 'inactive'}
    >
      <IcoCheck />
    </MotionCheck>
  );
};

export default BigCheck;
