import { Components, Theme } from '@mui/material';

export const MuiContainer: Components<Theme>['MuiContainer'] = {
  defaultProps: {
    disableGutters: true,
    maxWidth: 'md',
  },
  styleOverrides: {
    root: {
      position: 'relative',
    },
  },
};
