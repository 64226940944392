import Cookies from 'js-cookie';
import React from 'react';
import { redirect } from 'react-router-dom';

import { createIndexRoute } from 'utils/router.utils';

import { ONBOARDED } from 'constants/cookies.constants';
import { userLoader } from 'queries';
import { queryClient } from 'services/react-query';

import { Routes } from 'pages/routes.constants';

/**
 * use lazy imports to get code splitting out-of-the-box
 * you can use "webpackChunkName" to name this chunk
 */
// tslint:disable:space-in-parens
const componentPromise = import(
  './Home' /* webpackChunkName: "home", webpackPreload: true */
);
const Component = React.lazy(() => componentPromise);
// tslint:enable

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default createIndexRoute(Component, {
  index: true,
  loader: async () => {
    const isStandalone = window.matchMedia(
      '(display-mode: standalone)',
    ).matches;
    const onboarded = Cookies.get(ONBOARDED);

    if (!isStandalone && !Boolean(onboarded)) {
      return redirect(`${Routes.Onboarding}${window.location.search}`);
    }

    const user = await userLoader(queryClient);

    if (!user || window.location.search.includes('id')) {
      return redirect(`${Routes.Scan}${window.location.search}`);
    }

    return null;
  },
});
