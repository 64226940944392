import 'polyfills';

import * as Sentry from '@sentry/browser';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import config from 'config';

import { runInDev, runNotInDev } from 'utils/env.utils';
import registerErrorOverlay from 'utils/vite-error-overlay';

import { initAmplify, initPosthog } from 'services';

import App from './App';

initAmplify('app');
initPosthog('app');

runNotInDev(() =>
  Sentry.init({
    ...config.SENTRY,
    sampleRate: 0.1,
    environment: `${config.SENTRY.environment}-consumer`,
  }),
);

runInDev(() => {
  registerErrorOverlay();
  // Enable to start mocking!
  // const { worker } = require('./mocks/browser');
  // worker.start({ onUnhandledRequest: 'bypass' });
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
