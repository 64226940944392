import { memo, SVGProps } from 'react';

const SvgIcoTablewareCup = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" {...props}>
    <path
      d="M10.6667 20L9 8H19L17.3333 20H10.6667Z"
      fill="currentFill"
      stroke="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoTablewareCup);
export default Memo;
