import { CssBaseline, ThemeProvider } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createContext } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { isDev } from 'utils/env.utils';

import IntlSelectorProvider from 'services/i18n';
import { persisOptions, queryClient } from 'services/react-query';
import storeConfig from 'store';
import theme from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary, RouteBoundary } from 'components/@boundaries';
import { LoadingState } from 'components/@states';

export const AppTypeContext = createContext<'app' | 'resto'>('app');

const App = ({ isResto }: { isResto?: boolean }) => (
  <AppTypeContext.Provider value={isResto ? 'resto' : 'app'}>
    <RootBoundary>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={persisOptions}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ReduxProvider store={storeConfig.store}>
            <PersistGate
              loading={<LoadingState />}
              persistor={storeConfig.persistor}
              onBeforeLift={storeConfig.initApp}
            >
              <IntlSelectorProvider>
                <HelmetProvider>
                  <RouteBoundary>
                    <Pages />
                  </RouteBoundary>
                </HelmetProvider>
              </IntlSelectorProvider>
            </PersistGate>
          </ReduxProvider>
        </ThemeProvider>
        {isDev() && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        )}
      </PersistQueryClientProvider>
    </RootBoundary>
  </AppTypeContext.Provider>
);

export default App;
