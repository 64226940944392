import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { RouterProvider } from 'react-router-dom';

import { useIsResto } from 'hooks';
import { sentryCreateBrowserRouter } from 'services/sentry';

import { routes } from './routes';

const Pages = () => {
  const isResto = useIsResto();
  const platform = isResto ? 'resto' : 'app';
  const router = sentryCreateBrowserRouter(routes[platform]);

  const intl = useIntl();
  return (
    <Suspense>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: isResto ? 'resto.seo.title' : 'consumer.seo.title',
          })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({
            id: isResto ? 'resto.seo.description' : 'consumer.seo.description',
          })}
        />
      </Helmet>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Pages;
