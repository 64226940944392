import { memo, SVGProps } from 'react';

const SvgBorroLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 78 38" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.78 31.69C13.78 34.66 10.92 37.1 7.42 37.1H0V36.15H1.32V0.95H0V0H7.42C10.92 0 13.78 2.44 13.78 5.41V12.56C13.78 14.73 12.24 16.64 10.07 17.49C12.24 18.39 13.78 20.25 13.78 22.42V31.69ZM9.54 14.89V3.07C9.54 1.9 8.59 0.95 7.42 0.95H5.57V17.01H7.42C8.59 17.01 9.54 16.06 9.54 14.89ZM9.54 34.02V20.08C9.54 18.91 8.59 17.96 7.42 17.96H5.57V36.14H7.42C8.59 36.14 9.54 35.19 9.54 34.02ZM28.67 31.96C28.67 34.93 26.18 37.37 22.68 37.37C19.18 37.37 16.69 34.93 16.69 31.96V14.68C16.69 11.71 19.18 9.27 22.68 9.27C26.18 9.27 28.67 11.71 28.67 14.68V31.96ZM24.54 34.29V12.35C24.54 11.18 23.85 10.23 22.69 10.23C21.53 10.23 20.84 11.18 20.84 12.35V34.29C20.84 35.46 21.53 36.41 22.69 36.41C23.85 36.41 24.54 35.46 24.54 34.29ZM38.9 17.96H43.03V14.15C43.03 10.97 41.87 9.27 38.9 9.27C37.69 9.27 36.78 9.59 35.99 10.28V9.54H30.53V10.49H31.86V36.14H30.53V37.09H37.31V36.14H35.99V11.23C36.52 10.65 37.05 10.33 37.63 10.33C38.79 10.33 38.9 11.17 38.9 12.34V17.96ZM56.87 17.96H52.74V12.34C52.74 11.17 52.63 10.33 51.47 10.33C50.89 10.33 50.36 10.65 49.83 11.23V36.14H51.15V37.09H44.37V36.14H45.7V10.49H44.37V9.54H49.83V10.28C50.62 9.59 51.53 9.27 52.74 9.27C55.71 9.27 56.87 10.97 56.87 14.15V17.96ZM64.97 37.37C68.47 37.37 70.96 34.93 70.96 31.96V14.68C70.96 11.71 68.47 9.27 64.97 9.27C61.47 9.27 58.98 11.71 58.98 14.68V31.96C58.98 34.93 61.47 37.37 64.97 37.37ZM66.83 12.35V34.29C66.83 35.46 66.14 36.41 64.98 36.41C63.82 36.41 63.13 35.46 63.13 34.29V12.35C63.13 11.18 63.82 10.23 64.98 10.23C66.14 10.23 66.83 11.18 66.83 12.35Z"
      fill="currentColor"
    />
    <path d="M77.64 37.1H73.56V30.74H77.64V37.1Z" fill="#FF9100" />
  </svg>
);
const Memo = memo(SvgBorroLogo);
export default Memo;
